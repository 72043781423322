.d-block{
    width: 100%;
    height: 500px;
}

.navimage{
width: 250px;
height: 60px;
}
.card-img-top{
    height: 500px;
}
.g-recaptcha {
    transform:scale(0.77);
-webkit-transform:scale(0.77);
transform-origin:0 0;
-webkit-transform-origin:0 0;
}
.in{
    height: 300px;
}
.fg{
    color: #ef8308;
}
.gf{
    color: rgb(14, 169, 14);
}
.td{
    color: #e57236;
}
.gh{
    background-color:#e57236 ;
}

.ghhj{
    background-color:#e57236 ;
    border: 2px solid #e57236 ;
    border-radius: 5px;
}